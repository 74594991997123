@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/mixins"

























































.tour-finished__modal
  display: flex
  flex-direction: column
  width: 672px
  position: fixed
  z-index: 1000
  bottom: 32px
  left: 32px
  transition: all 200ms ease-in-out
  pointer-events: all !important
  transform-origin: bottom
  padding: 0px !important

  +mq-l
    width: auto
    bottom: 16px
    left: 16px
    right: 16px

  +mq_landscape
    flex-direction: row

  &__body
    display: flex

  &__title
    margin-bottom: 8px

    +mq-l
      font-size: 13px
      padding: 8px 8px 8px 0px
      box-sizing: border-box

  &__description
    color: $color-ink-light

  &__image
    align-items: center
    display: flex

    img

      +mq-l
        width: 105px

      +mq_landscape
        width: auto

  &__message
    display: flex
    flex-direction: column
    justify-content: center
    margin-left: 24px

    +mq-l
      margin-left: 0px

  &__actions
    display: flex
    align-items: center
    justify-content: flex-end
    color: $color-primary
    margin-top: -48px
    margin-right: 64px
    padding-bottom: 16px

    +mq-l
      margin-top: 0
      margin-right: 0
      padding-bottom: 0
      border-top: 1px solid $color-ink-lightest

    +mq_landscape
      flex-direction: column
      border-top: unset
      border-left: 1px solid $color-ink-lightest

    .btn
      margin-left: 16px
      color: $color-primary

      +mq-l
        font-size: 13px

      &:hover
        background: transparentize($color-ink-lightest, 0.2)

  &__question
    font-size: 15px
    font-weight: 600
    width: 50%

    +mq-l
      font-size: 12px
      font-weight: 400
      margin: 8px 0px 16px 16px
      width: 100%

    +mq_landscape
      padding: 16px 24px
      box-sizing: border-box
      width: 100%
      margin: 0

  &__buttons
    display: flex

  &__exit
    position: absolute !important
    top: 16px
    right: 16px
    color: $color-ink-lighter !important

  &-enter,
  &-leave-to
    opacity: 0

  &-enter-active
    animation: show-in 700ms ease-in-out

  &-leave-active
    animation: show-out 400ms ease-in-out

@keyframes show-in
  0%
    opacity: 0
    transform: scale(0)

  50%
    transform: scale(1.08)

  100%
    opacity: 1
    transform: scale(1)

@keyframes show-out
  0%
    opacity: 1
    transform: scale(1)
  100%
    opacity: 0
    transform: scale(0)

